// @ts-ignore
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Layout } from 'components/Layout/Layout';
import styled, { css } from 'styled-components';
import { Footer } from 'components/Layout/Footer';
import { Socials } from 'components/Socials/Socials';
import { ActionButton } from 'components/Buttons/ActionButton';
import { FlexWrapper, FlexBox } from 'components/Layout/Flex';
import { Image } from 'components/Layout/Image';
import { Spacer } from 'components/Layout/Spacer';
import { Videos } from 'components/Videos/Videos';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { scrollTop, getRandomNumber } from 'utils';
import Countdown from 'react-countdown';
import { useMediaQuery } from 'react-responsive';
import Link from 'next/link';
import { Header } from '../components/Layout/Header';
import { Consts, Consts as StyleConsts } from '../styles/consts';
import StatisticsSlide from '../components/StatisticsSlide/StatisticsSlide';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span>Registration is open now!</span>;
  }
  return (
    <div>
      <div>
        <p>{days}</p>
        <p>days</p>
      </div>
      <div>
        <p>{hours}</p>
        <p>hours</p>
      </div>
      <div>
        <p>{minutes}</p>
        <p>minutes</p>
      </div>
      <div>
        <p>{seconds}</p>
        <p>seconds</p>
      </div>
    </div>
  );
};

function Home({ activeVideo }) {
  const [isMuted, mute] = useState(true);
  const [isMobile, setMobile] = useState(false);
  const mobile = useMediaQuery({
    query: `(max-width: ${StyleConsts.mobileBreakPoint})`,
  });

  useEffect(() => {
    setMobile(mobile);
  }, [mobile]);

  // @ts-ignore
  return (
    <Layout>
      <Head>
        <title>The Earth Prize</title>
        <meta
          name="description"
          content="The Earth Prize is the environmental sustainability competition for teenage students. The Earth Prize will recognize the students and schools with the best solutions to accelerate positive change towards environmental sustainability, as judged by The Earth Prize Adjudicating Panel."
        />
        <meta property="og:title" content="The Earth Prize" />
        <meta
          property="og:description"
          content="The Earth Prize is the environmental sustainability competition for teenage students. The Earth Prize will recognize the students and schools with the best solutions to accelerate positive change towards environmental sustainability, as judged by The Earth Prize Adjudicating Panel."
        />
        <meta property="og:image" content="/images/cover.jpg" />
      </Head>
      <Header />
      <FlexWrapper>
        <FlexBox
          padding={isMobile ? '80px 20px 25px' : '60px'}
          background="#000000"
          color="#00B7BD"
          justify="flex-end"
        >
          {/*<CounterWrapper>*/}
          {/*  <p>Registration opens in:</p>*/}
          {/*  <Countdown date="2024-09-01T00:00:00" renderer={renderer} />*/}
          {/*</CounterWrapper>*/}
          <Subtitle>
            The Earth Prize 2025
            <br />
            is here!
          </Subtitle>
          {/*<Spacer height={isMobile ? 5 : 10} />*/}
          {/*<HeroText color="#ED9F6E">*/}
          {/*  The Earth Prize 2024 Finalists have been selected! Stay tuned to*/}
          {/*  discover the top Winner and Runners-up on April 22nd.*/}
          {/*</HeroText>*/}
          <Spacer height={isMobile ? 15 : 30} />
          <ButtonsWrapper>
            <Link href="/registration">
              <ActionButton background="white" color="black">
                REGISTER NOW
              </ActionButton>
            </Link>
            <Link href="/2025">
              <ActionButton background="#a95c5c" color="white">
                EVERYTHING YOU NEED TO KNOW
              </ActionButton>
            </Link>
          </ButtonsWrapper>
          <Spacer height={25} />
          <Socials background="white" color="#000000" />
        </FlexBox>
        <FlexBox background="#000000" color="#F7F7F0" withoutPadding>
          <Videos isMuted={isMuted} activeVideo={activeVideo} />
          <VolumeWrapper>
            <ActionButton isSmall onClick={() => mute(!isMuted)}>
              <FontAwesomeIcon
                icon={
                  isMuted
                    ? (faVolumeMute as IconProp)
                    : (faVolumeUp as IconProp)
                }
              />
            </ActionButton>
          </VolumeWrapper>
        </FlexBox>
      </FlexWrapper>
      <StatisticsSlide />
      <FlexWrapper>
        <FlexBox background="#0AB7BC" color="#000000" withoutPadding>
          <Image image="/images/system-change.webp" />
        </FlexBox>
        <FlexBox background="#AFE0D7" color="#51888D" justify="center">
          <Socials background="white" color="#51888D" />
          <Spacer height={15} />
          <H1 color="#51888D">
            ABOUT THE
            <br />
            EARTH PRIZE
          </H1>
          <Spacer height={10} />
          <Text color="#51888D">
            Through The Earth Prize competition, students are inspired,
            educated, mentored and empowered to solve environmental
            sustainability problems.
          </Text>
          <Spacer height={30} />
          <Link href="/schools">
            <ActionButton background="white" color="#51888D">
              Learn more
            </ActionButton>
          </Link>
        </FlexBox>
      </FlexWrapper>
      <FlexWrapper>
        <FlexBox background="#008A77" color="#000000" withoutPadding>
          <Image image="/images/wind.webp" />
        </FlexBox>
        <FlexBox background="#008A77" color="#ffffff" justify="center">
          <H1>
            SOLVE THE <br />
            UNTHINKABLE
          </H1>
          <Spacer height={10} />
          <Text color="#ffffff">
            Over 2000 Schools
            <br />
            154 Countries and Territories
          </Text>
          <br />
          <Text color="#ffffff">
            A global environmental sustainability competition for students
            between the ages of 13 and 19.
          </Text>
          <Spacer height={10} />
          <Text color="#ffffff">
            Learn more about how we are inspiring our participants.
          </Text>
          <Spacer height={20} />
          <Link href="/learning-content">
            <ActionButton background="#ffffff" color="#008A77">
              LEARNING CONTENT
            </ActionButton>
          </Link>
        </FlexBox>
      </FlexWrapper>
      <FlexWrapper isReversedDirection>
        <FlexBox background="#0AB7BC" color="#000000" withoutPadding>
          <Image image="/images/city.webp">
            <Challenge>
              <FloatText>Challenge everything</FloatText>
            </Challenge>
          </Image>
        </FlexBox>
        <FlexBox background="#78494D" color="#EDA06D" justify="center">
          <Socials background="#EDA06D" color="#78494D" />
          <Spacer height={15} />
          <H1>#UNPOLLUTED</H1>
          <Spacer height={10} />
          <Text color="#EDA06D">
            Game-changing ideas can come from anywhere.
            <br /> Yours could make a difference.
            <br /> But first you have to enter the competition.
          </Text>
          <Spacer height={40} />
          <Link href="/competition">
            <ActionButton background="#EDA06D" color="#78494D">
              The Competition
            </ActionButton>
          </Link>
        </FlexBox>
      </FlexWrapper>
      <FlexWrapper isReversedDirection>
        <FlexBox background="#008A77" color="#000000" withoutPadding>
          <Image image="/images/playgroundClear.webp" />
        </FlexBox>
        <FlexBox background="white" color="#1d8676" justify="center">
          <Spacer height={15} />
          <H1>VOLUNTEER COMMUNITY</H1>
          <Spacer height={10} />
          <Text color="#1d8676">
            Are you passionate about environmental sustainability? Join our
            dynamic global community dedicated to empowering youth to tackle
            today&apos;s sustainability and environmental challenges head-on.
          </Text>
          <Spacer height={40} />
          <Link href="/volunteer">
            <ActionButton background="#1d8676" color="white">
              Learn more
            </ActionButton>
          </Link>
        </FlexBox>
      </FlexWrapper>
      <FlexWrapper>
        <FlexBox background="#000000" color="#F7F7F0" withoutPadding>
          <Image image="/images/earthFooter.webp" position="top" />
        </FlexBox>
        <FlexBox background="#317E88" color="#AEDFD6" justify="center">
          <H1>ABOUT US</H1>
          <Spacer height={10} />
          <Text color="#AEDFD6">
            Who are we? What is this all about?
            <br /> Let us explain…
          </Text>
          <Spacer height={40} />
          <Link href="/about-us">
            <ActionButton background="#AEDFD6" color="#317E88">
              The Info
            </ActionButton>
          </Link>
        </FlexBox>
      </FlexWrapper>
      <Footer fullHeight onTopClick={() => scrollTop()} />
    </Layout>
  );
}

export async function getStaticProps() {
  return {
    props: {
      activeVideo: getRandomNumber(0, 4),
    },
    revalidate: 1,
  };
}

interface IText {
  color?: string;
  size?: string;
  lineHeight?: string;
}

const CounterWrapper = styled.div`
  background: white;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 100px;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 500px;
  gap: 10px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);

  > p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: black;
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 15px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 45px;

      > p {
        color: black;

        &:nth-child(1) {
          font-weight: 700;
          font-size: 24px;
        }

        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: ${Consts.smallTabletBreakPoint}) {
    border-radius: 50px;
    margin-bottom: 20px;
    flex-direction: column;
    background-color: #f6f3ef;
    gap: 2px;
    padding: 5px 10px;
    width: 100%;

    > p {
      font-size: 11px;
    }

    > div {
      gap: 5px;

      > div {
        > p {
          color: black;

          &:nth-child(1) {
            font-size: 16px;
          }

          &:nth-child(2) {
            font-size: 10px;
          }
        }
      }
    }
  }
`;

const VolumeWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    z-index: 1;
    position: absolute;
    bottom: 60px;
    left: 60px;

    button {
      color: black !important;
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      left: 25px;
      bottom: 40px;
    }
  `}
`;

const FloatText = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 48%;
    font-size: 28px;
    font-family: 'Mokoko', serif;
    font-weight: bold;
    color: #eda06d;
    position: absolute;
    top: calc(50% - 85px);
    left: calc(50% - 95px);

    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 22px;
      top: calc(50% - 65px);
      left: calc(50% - 75px);
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      font-size: 15px;
      top: 50%;
      left: 50%;
      transform: translateX(-60%) translateY(-100%);
    }
  `}
`;

const Challenge = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 60%;
    height: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/images/challenge.svg');
    background-size: contain;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;

    @media (min-width: ${Consts.mobileBreakPoint}) {
      width: 60%;
      height: 90%;
    }
    @media (min-width: ${Consts.tabletBreakPoint}) {
      width: 60%;
      height: 100%;
    }
  `}
`;

const ButtonsWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 100%;
    display: flex;

    button {
      margin: 0 20px 0 0;

      @media (max-width: ${Consts.mobileBreakPoint}) {
        margin: 0 10px 0 0;
      }
    }
  `}
`;

const H1 = styled.h1<IText>`
  ${({ size, lineHeight, color, theme: { Consts } }) => css`
    font-size: 28px;
    color: ${color};
    font-weight: bold;
    @media (min-width: ${Consts.mobileBreakPoint}) {
      font-size: 38px;
    }
    @media (min-width: ${Consts.tabletBreakPoint}) {
      font-size: ${size || '52px'};
      line-height: ${lineHeight || '62px'};
    }
  `}
`;

const Subtitle = styled.p`
  ${({ theme: { Consts } }) => css`
    font-size: 21px;
    text-transform: uppercase;
    font-weight: bold;
    @media (min-width: ${Consts.mobileBreakPoint}) {
      font-size: 28px;
    }
    @media (min-width: ${Consts.tabletBreakPoint}) {
      font-size: 38px;
    }
  `}
`;

const HeroText = styled.p<IText>`
  ${({ color = 'black', size, theme: { Consts } }) => css`
    font-size: ${size || '22px'};
    font-weight: bold;
    line-height: 32px;
    color: ${color};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 18px;
      line-height: 28px;
    }
  `}
`;

const Text = styled.p<IText>`
  ${({ color = 'black', size, theme: { Consts } }) => css`
    font-size: ${size || '18px'};
    color: ${color};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 14px;
    }
  `}
`;

export default Home;
