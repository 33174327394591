import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { video1, video2, video3, video4, video5 } from 'consts';

const videos = [video1, video2, video3, video4, video5];

interface IVideos {
  isMuted: boolean;
  activeVideo: number;
}

export const Videos: FunctionComponent<IVideos> = ({
  isMuted,
  activeVideo,
}) => {
  return (
    <VideoWrapper>
      <video autoPlay loop muted={isMuted} playsInline preload="auto">
        <source src={videos[activeVideo]} type="video/mp4" />
      </video>
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    height: 100%;
    width: 100%;
    position: absolute;
    padding-top: 105px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding-top: 80px;
    }

    @media (max-width: ${Consts.smallTabletBreakPoint}) {
      padding-top: 0;
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      padding-top: 0;
    }

    video {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;

      @media (max-width: ${Consts.mobileBreakPoint}) {
        object-fit: cover;
        object-position: center;
      }

      &__hidden {
        visibility: hidden;
      }
    }
  `}
`;
