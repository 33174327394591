import React, { FunctionComponent, useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FlexWrapper } from '../Layout/Flex';
import { Consts as StyleConsts } from '../../styles/consts';

const data = [
  { name: '19', value: 0.9 },
  { name: '18', value: 4.9 },
  { name: '17', value: 15.8 },
  { name: '16', value: 21.5 },
  { name: '15', value: 15.5 },
  { name: '14', value: 36.9 },
  { name: '13', value: 4.5 },
];

const COLORS = [
  '#e2a375',
  '#C07367',
  '#487D87',
  '#54B4BB',
  '#418476',
  '#41656F',
  '#BADFD7',
];

const renderPieLabel = (props) => {
  const cyNumber = props.name === '19' ? 65 : 20;
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, name, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 2) * cos;
  const sy = cy + (outerRadius + 2) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + cyNumber) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fontSize={16}
        fill="white"
      >
        {name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        fontSize={16}
        style={{ fontWeight: 'bold' }}
        textAnchor={textAnchor}
        fill="#BADFD7"
      >
        {value}%
      </text>
    </g>
  );
};

const StatisticsSlide: FunctionComponent = () => {
  const [openedTheme, openTheme] = useState('');
  const [isMobile, setMobile] = useState(false);
  const mobile = useMediaQuery({
    query: `(max-width: ${StyleConsts.tabletBreakPoint})`,
  });

  useEffect(() => {
    setMobile(mobile);
  }, [mobile]);

  return (
    <FlexWrapper isfullPage noMaxHeight>
      <StatisticsWrapper>
        <StatisticsTopWrapper>
          <div>
            <h1 color="white">THE EARTH PRIZE IN NUMBERS</h1>
            <p>2022-2024</p>
          </div>
          {isMobile ? (
            <div>
              <div>
                <StatisticsCounters>
                  <div
                    style={{
                      backgroundImage: 'url("/images/studentsIcon.svg")',
                    }}
                  />
                  <p style={{ color: '#B8DDD6' }}>10,444</p>
                  <p>Students</p>
                </StatisticsCounters>
                <StatisticsCounters>
                  <div
                    style={{
                      backgroundImage: 'url("/images/teachersIcon.svg")',
                    }}
                  />
                  <p style={{ color: '#E2A375' }}>2,684</p>
                  <p>Teachers</p>
                </StatisticsCounters>
              </div>
              <ArrowDown />
              <div>
                <StatisticsCounters>
                  <div
                    style={{
                      backgroundImage: 'url("/images/schoolsIcon.svg")',
                    }}
                  />
                  <p style={{ color: '#A95C5C' }}>2,468</p>
                  <p>Schools</p>
                </StatisticsCounters>
                <StatisticsCounters>
                  <div
                    style={{
                      backgroundImage: 'url("/images/countriesIcon.svg")',
                    }}
                  />
                  <p style={{ color: '#1D8676' }}>154</p>
                  <p>Countries</p>
                </StatisticsCounters>
              </div>
            </div>
          ) : (
            <div>
              <ArrowRight />
              <StatisticsCounters>
                <div
                  style={{ backgroundImage: 'url("/images/studentsIcon.svg")' }}
                />
                <p style={{ color: '#B8DDD6' }}>10,444</p>
                <p>Students</p>
              </StatisticsCounters>
              <ArrowRight />
              <StatisticsCounters>
                <div
                  style={{ backgroundImage: 'url("/images/teachersIcon.svg")' }}
                />
                <p style={{ color: '#E2A375' }}>2,684</p>
                <p>Teachers</p>
              </StatisticsCounters>
              <ArrowRight />
              <StatisticsCounters>
                <div
                  style={{ backgroundImage: 'url("/images/schoolsIcon.svg")' }}
                />
                <p style={{ color: '#A95C5C' }}>2,468</p>
                <p>Schools</p>
              </StatisticsCounters>
              <ArrowRight />
              <StatisticsCounters>
                <div
                  style={{
                    backgroundImage: 'url("/images/countriesIcon.svg")',
                  }}
                />
                <p style={{ color: '#1D8676' }}>154</p>
                <p>Countries</p>
              </StatisticsCounters>
            </div>
          )}
        </StatisticsTopWrapper>
        <StatisticsContentWrapper>
          {!isMobile && (
            <SideCountersWrapper>
              <ArrowDown />
              <StatisticsCounters>
                <span />
                <p style={{ color: '#B8DDD6' }}>3,140</p>
                <p>Solutions</p>
              </StatisticsCounters>
              <ArrowDown />
              <StatisticsCounters>
                <span />
                <p style={{ color: '#E2A375' }}>106</p>
                <p>Scholars</p>
              </StatisticsCounters>
              <ArrowDown />
              <StatisticsCounters>
                <span />
                <p style={{ color: '#A95C5C' }}>30</p>
                <p>Finalists</p>
              </StatisticsCounters>
              <ArrowDown />
              <StatisticsCounters>
                <span />
                <p style={{ color: '#1D8676' }}>22</p>
                <p>Awardees</p>
              </StatisticsCounters>
            </SideCountersWrapper>
          )}
          <div>
            <MapWrapper>
              <h2>Registered Schools</h2>
              <div>
                <div>
                  {isMobile ? 'NA' : 'North America'} <b>17.5%</b>
                </div>
                <div>
                  {isMobile ? 'SA' : 'South America'} <b>3.8%</b>
                </div>
                <div>
                  Europe <b>13.1%</b>
                </div>
                <div>
                  Africa <b>12.3%</b>
                </div>
                <div>
                  Asia <b>51.5%</b>
                </div>
                <div>
                  Oceania <b>1.1%</b>
                </div>
              </div>
            </MapWrapper>
            {isMobile && (
              <SideCountersWrapper>
                <div>
                  <StatisticsCounters>
                    <span />
                    <p style={{ color: '#B8DDD6' }}>3,140</p>
                    <p>Solutions</p>
                  </StatisticsCounters>
                  <StatisticsCounters>
                    <span />
                    <p style={{ color: '#E2A375' }}>106</p>
                    <p>Scholars</p>
                  </StatisticsCounters>
                </div>
                <ArrowDown />
                <div>
                  <StatisticsCounters>
                    <span />
                    <p style={{ color: '#A95C5C' }}>30</p>
                    <p>Finalists</p>
                  </StatisticsCounters>
                  <StatisticsCounters>
                    <span />
                    <p style={{ color: '#1D8676' }}>22</p>
                    <p>Awardees</p>
                  </StatisticsCounters>
                </div>
              </SideCountersWrapper>
            )}
            {!isMobile && (
              <CirclesWrapper>
                <div>
                  <div />
                  <p>Global</p>
                  <p>66.1%</p>
                </div>
                <div>
                  <div />
                  <p>National</p>
                  <p>15.9%</p>
                </div>
                <div>
                  <div />
                  <p>Local</p>
                  <p>18%</p>
                </div>
                <div>
                  <h2>
                    Solution
                    <br />
                    Scope
                  </h2>
                </div>
              </CirclesWrapper>
            )}
          </div>
          <MentorsWrapper>
            <div>
              <h2>Mentors</h2>
              <div>
                <p>129</p>
                <p>Mentors</p>
              </div>
              <div>
                <p>40 hours</p>
                <p>One:one mentorings</p>
              </div>
              <div>
                <p>2,500+</p>
                <p>Questions asked</p>
              </div>
              <div>
                <p>92</p>
                <p>Universities</p>
              </div>
            </div>
            <div>
              <h2>Total hours of content watched</h2>
              <div>
                <div>
                  <div />
                  <p>1,004</p>
                </div>
              </div>
            </div>
          </MentorsWrapper>
          <div>
            <PieChartWrapper>
              <p>
                Age of
                <br />
                Participants
              </p>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={data}
                    stroke="none"
                    dataKey="value"
                    cx="50%"
                    startAngle={90}
                    endAngle={450}
                    cy="50%"
                    innerRadius={70}
                    outerRadius={90}
                    isAnimationActive={false}
                    label={renderPieLabel}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={entry.value}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </PieChartWrapper>
            {isMobile ? (
              <SubmissionThemesWrapper>
                <h2>Submission Themes</h2>
                <div>
                  <div>
                    <div>
                      <h2
                        onClick={() =>
                          openedTheme === 'waste'
                            ? openTheme('')
                            : openTheme('waste')
                        }
                      >
                        Waste&nbsp;&nbsp;
                        <FontAwesomeIcon
                          style={{
                            transform:
                              openedTheme === 'waste'
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                          }}
                          icon={faLongArrowAltUp as IconProp}
                        />
                      </h2>
                      {openedTheme === 'waste' && (
                        <>
                          <p>Overconsumption 4.7%</p>
                          <p>Waste management 12%</p>
                          <p>Textile waste 3.3%</p>
                          <p>Plastic waste 9.2%</p>
                          <p>Food waste 5.7%</p>
                        </>
                      )}
                    </div>
                    <div>
                      <h2
                        onClick={() =>
                          openedTheme === 'pollution'
                            ? openTheme('')
                            : openTheme('pollution')
                        }
                      >
                        Pollution&nbsp;&nbsp;
                        <FontAwesomeIcon
                          style={{
                            transform:
                              openedTheme === 'pollution'
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                          }}
                          icon={faLongArrowAltUp as IconProp}
                        />
                      </h2>
                      {openedTheme === 'pollution' && (
                        <>
                          <p>Water pollution 10.1%</p>
                          <p>Non-renewable energy 5.6%</p>
                          <p>Air pollution 7.9%</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <h2
                        onClick={() =>
                          openedTheme === 'environment'
                            ? openTheme('')
                            : openTheme('environment')
                        }
                      >
                        Environment&nbsp;&nbsp;
                        <FontAwesomeIcon
                          style={{
                            transform:
                              openedTheme === 'environment'
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                          }}
                          icon={faLongArrowAltUp as IconProp}
                        />
                      </h2>
                      {openedTheme === 'environment' && (
                        <>
                          <p>Ocean degradation 5.1%</p>
                          <p>Biodiversity loss 7.1%</p>
                          <p>Climate change 11.6%</p>
                          <p>Deforestation 3.7%</p>
                          <p>Drought 2.6%</p>
                          <p>Land degradation 5.6%</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </SubmissionThemesWrapper>
            ) : (
              <SubmissionThemesWrapper>
                <h2>Submission Themes</h2>
                <div>
                  <div>
                    <div>
                      <h2>Waste</h2>
                      <p>Overconsumption 4.7%</p>
                      <p>Waste management 12%</p>
                      <p>Textile waste 3.3%</p>
                      <p>Plastic waste 9.2%</p>
                      <p>Food waste 5.7%</p>
                    </div>
                    <div>
                      <h2>Pollution</h2>
                      <p>Water pollution 10.1%</p>
                      <p>Non-renewable energy 5.6%</p>
                      <p>Air pollution 7.9%</p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h2>Environment</h2>
                      <p>Ocean degradation 5.1%</p>
                      <p>Biodiversity loss 7.1%</p>
                      <p>Climate change 11.6%</p>
                      <p>Deforestation 3.7%</p>
                      <p>Drought 2.6%</p>
                      <p>Land degradation 5.6%</p>
                    </div>
                  </div>
                </div>
              </SubmissionThemesWrapper>
            )}
          </div>
          {isMobile && (
            <CirclesWrapper>
              <div>
                <div />
                <p>Global</p>
                <p>66.1%</p>
              </div>
              <div>
                <div />
                <p>National</p>
                <p>15.9%</p>
              </div>
              <div>
                <div />
                <p>Local</p>
                <p>18%</p>
              </div>
              <div>
                <h2>
                  Solution
                  <br />
                  Scope
                </h2>
              </div>
            </CirclesWrapper>
          )}
        </StatisticsContentWrapper>
      </StatisticsWrapper>
    </FlexWrapper>
  );
};

const SubmissionThemesWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 0 40px;
      max-width: 750px;
      width: 100%;
      margin: 0 auto;
    }

    > h2 {
      font-size: 16px;
      margin-bottom: 30px;
      @media (max-width: ${Consts.tabletBreakPoint}) {
        text-align: center;
      }
    }

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: stretch;
      gap: 20px;

      @media (max-width: ${Consts.tabletBreakPoint}) {
        grid-template-columns: 2fr 1fr;
      }

      @media (max-width: ${Consts.mobileBreakPoint}) {
        grid-template-columns: 1fr;
      }

      > div {
        > div {
          margin-bottom: 15px;
          > h2 {
            font-size: 16px;
            margin-bottom: 7px;
            @media (max-width: ${Consts.tabletBreakPoint}) {
              text-align: center;
            }
          }
          > p {
            color: white;
            font-size: 14px;
            margin-bottom: 7px;
            white-space: nowrap;
            @media (max-width: ${Consts.tabletBreakPoint}) {
              text-align: center;
            }
          }
        }
        &:nth-child(1) {
          @media (max-width: ${Consts.tabletBreakPoint}) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
          }
          @media (max-width: ${Consts.mobileBreakPoint}) {
            grid-template-columns: 1fr;
          }
          > div {
            &:nth-child(1) {
              > h2 {
                color: #c17366;
              }
            }
            &:nth-child(2) {
              > h2 {
                color: #e2a375;
              }
            }
          }
        }
        &:nth-child(2) {
          > div {
            &:nth-child(1) {
              > h2 {
                color: #1d8676;
              }
            }
          }
        }
      }
    }
  `}
`;

const PieChartWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    position: relative;
    overflow: hidden;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      height: 350px;
      width: 100%;
      margin-bottom: 40px;
    }

    > p {
      position: absolute;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  `}
`;

const MentorsWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: grid;
    grid-template-rows: 1fr 200px;
    align-items: stretch;
    gap: 20px;
    padding-bottom: 30px;
    padding-top: 25px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 0 40px;
      margin-bottom: 20px;
    }

    > div {
      &:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        background-color: #072525;
        border-radius: 10px;
        padding: 10px 15px 26px;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          align-items: center;
          gap: 15px;
          max-width: 450px;
          width: 100%;
          margin: 0 auto 30px;
        }

        > h2 {
          font-size: 16px;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            text-align: center;
          }
        }
        > div {
          > p {
            &:nth-child(1) {
              font-size: 28px;
              font-weight: bold;
              color: #21b7bb;
              @media (max-width: ${Consts.tabletBreakPoint}) {
                text-align: center;
              }
            }
            &:nth-child(2) {
              font-size: 16px;
              @media (max-width: ${Consts.tabletBreakPoint}) {
                text-align: center;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: ${Consts.tabletBreakPoint}) {
          max-width: 200px;
          margin: 0 auto;
        }

        > h2 {
          font-size: 16px;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            text-align: center;
            padding: 0 20px;
          }
        }

        > div {
          flex-grow: 1;
          background-color: #072525;
          border-radius: 10px;
          position: relative;
          padding: 20px;

          > div {
            height: 100%;
            background-color: black;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            > div {
              background-image: url('/images/playButtonWhite.svg');
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              height: 15px;
              width: 15px;
              position: absolute;
              top: 5px;
              right: 5px;
            }

            > p {
              font-size: 32px;
              color: #21b7bb;
              font-weight: bold;
            }
          }

          &:after {
            content: '';
            position: absolute;
            width: 115%;
            height: 20px;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
            background-image: url('/images/laptopBottom.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  `}
`;

const CirclesWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    position: relative;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      height: 400px;
    }

    > div {
      position: absolute;
      border-radius: 50%;
      bottom: 0;
      height: 0;
      left: 50%;
      overflow: hidden;
      transform: translateY(45%) translateX(-50%);

      &:nth-child(1) {
        width: 100%;
        padding-bottom: 100%;
        background: #082525;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          width: 700px;
          padding-bottom: 700px;
        }

        > p {
          &:nth-child(2) {
            top: 5%;
            left: 50%;
            background: #082525;
            transform: translateX(-50%);

            @media (max-width: ${Consts.tabletBreakPoint}) {
              top: 7%;
            }
          }
          &:nth-child(3) {
            right: 1%;
            top: 42%;
            background: #082525;
            transform: translateY(-45%);

            @media (max-width: ${Consts.tabletBreakPoint}) {
              top: 11%;
              right: auto;
              left: 50%;
              transform: translateX(-50%);
              color: white;
            }

            &::after {
              content: '';
              width: 200px;
              height: 500px;
              position: absolute;
              bottom: 0;
              transform: translateY(100%);
              display: block;
              background: #082525;
            }
          }
        }

        > div {
          width: 90%;
          height: 90%;
          top: 8%;
          right: 6%;
          transform: rotate(-40deg);
        }
      }

      &:nth-child(2) {
        width: 70%;
        padding-bottom: 70%;
        background: #0a4243;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          width: 450px;
          padding-bottom: 450px;
        }

        > p {
          &:nth-child(2) {
            top: 2%;
            left: 50%;
            background: #0a4243;
            transform: translateX(-50%);

            @media (max-width: ${Consts.tabletBreakPoint}) {
              top: 5%;
            }
          }
          &:nth-child(3) {
            right: -3%;
            background: #0a4243;
            top: 37%;
            transform: translateY(-50%);

            @media (max-width: ${Consts.tabletBreakPoint}) {
              top: 11%;
              right: auto;
              left: 50%;
              transform: translateX(-50%);
              color: white;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              transform: translateY(100%);
              display: block;
              width: 200px;
              height: 500px;
              background: #0a4243;
            }
          }
        }

        > div {
          width: 90%;
          height: 90%;
          top: 7%;
          right: 5%;
          transform: rotate(-45deg);
        }
      }

      &:nth-child(3) {
        width: 52%;
        padding-bottom: 52%;
        background: #11585b;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          width: 300px;
          padding-bottom: 300px;
        }

        > p {
          &:nth-child(2) {
            top: 2%;
            left: 50%;
            background: #11585b;
            transform: translateX(-50%);
            @media (max-width: ${Consts.tabletBreakPoint}) {
              top: 4%;
            }
          }
          &:nth-child(3) {
            right: 1%;
            top: 31%;
            background: #11585b;
            transform: translateY(-50%);

            @media (max-width: ${Consts.tabletBreakPoint}) {
              top: 13%;
              right: auto;
              left: 50%;
              transform: translateX(-50%);
              color: white;
            }

            &::after {
              content: '';
              width: 200px;
              height: 500px;
              position: absolute;
              bottom: 0;
              transform: translateY(100%);
              display: block;
              background: #11585b;
            }
          }
        }

        > div {
          width: 85%;
          height: 85%;
          top: 9%;
          right: 7%;
          transform: rotate(-45deg);
        }
      }

      &:nth-child(4) {
        width: 35%;
        padding-bottom: 35%;
        background: #126d6e;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          width: 170px;
          padding-bottom: 170px;
        }

        > h2 {
          position: absolute;
          top: 17%;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          font-size: 20px;
        }
      }

      > p {
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        font-size: 16px;
        padding: 10px 15px;
        @media (max-width: ${Consts.tabletBreakPoint}) {
          padding: 0;
        }
      }

      > div {
        position: absolute;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid transparent;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
        border-radius: 50%;
        @media (max-width: ${Consts.tabletBreakPoint}) {
          display: none;
        }
      }
    }
  `}
`;

const MapWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding-top: 40px;
    }

    > h2 {
      font-size: 16px;
      text-align: center;
    }

    > div {
      background-image: url('/images/statisticsMap.png');
      background-size: contain;
      background-position: center;
      position: relative;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      flex-grow: 1;

      @media (max-width: ${Consts.tabletBreakPoint}) {
        min-height: 310px;
        background-size: auto 100%;
      }

      > div {
        position: absolute;
        padding: 5px;
        font-size: 14px;
        color: white;
        border-radius: 35px 35px 3px 35px;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          min-width: 115px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
        }

        &:nth-child(1) {
          background-color: #21b7bb;
          top: 10%;
          left: 0;

          @media (max-width: ${Consts.tabletBreakPoint}) {
            left: 51%;
            top: 10%;
            border-radius: 35px 35px 35px 3px;
          }
        }
        &:nth-child(2) {
          background-color: #b8ddd6;
          bottom: 25%;
          left: 5%;
          color: black;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            left: 49%;
            transform: translateX(-100%);
            bottom: auto;
            top: 40%;
          }
        }
        &:nth-child(3) {
          background-color: #e2a375;
          top: 25%;
          left: 30%;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            left: 51%;
            border-radius: 35px 35px 35px 3px;
            top: 25%;
          }
        }
        &:nth-child(4) {
          background-color: #a95c5c;
          top: 50%;
          border-radius: 35px 35px 35px 3px;
          left: 55%;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            border-radius: 35px 35px 3px 35px;
            left: 49%;
            transform: translateX(-100%);
            top: 25%;
          }
        }
        &:nth-child(5) {
          background-color: #1d8676;
          top: 20%;
          border-radius: 35px 35px 35px 3px;
          right: 10%;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            left: 49%;
            transform: translateX(-100%);
            top: 10%;
            border-radius: 35px 35px 3px 35px;
            right: auto;
          }
        }
        &:nth-child(6) {
          background-color: #2a6265;
          bottom: 25%;
          border-radius: 35px 35px 35px 3px;
          right: 0;
          @media (max-width: ${Consts.tabletBreakPoint}) {
            left: 51%;
            top: 40%;
            bottom: auto;
            right: auto;
          }
        }
      }
    }
  `}
`;

const StatisticsCounters = styled.div`
  ${({ theme: { Consts } }) => css`
    > div {
      height: 25px;
      width: 100%;
      margin-bottom: 3px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    > p {
      text-align: center;
      &:nth-child(2) {
        font-size: 28px;
        font-weight: bold;
      }
      &:nth-child(3) {
        font-size: 16px;
      }
    }

    @media (max-width: ${Consts.tabletBreakPoint}) {
      margin-bottom: 40px;
      margin-top: 40px;
    }
  `}
`;

const StatisticsWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    background-color: black;
    padding: 40px 50px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 40px 0 0;
    }
  `}
`;

const StatisticsTopWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: grid;
    grid-template-columns: 400px 1fr;
    align-items: center;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      grid-template-columns: 1fr;
    }

    > div {
      &:nth-child(1) {
        @media (max-width: ${Consts.tabletBreakPoint}) {
          > h1 {
            font-size: 30px;
            text-align: center;
          }
          > p {
            font-size: 20px;
            text-align: center;
            margin-bottom: 40px;
            margin-top: 10px;
          }
        }
      }
      &:nth-child(2) {
        display: grid;
        grid-template-columns: 1fr 110px 1fr 110px 1fr 110px 1fr 110px;
        align-items: center;
        gap: 10px;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          grid-template-columns: 1fr 1px 1fr;
          max-width: 550px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  `}
`;

const ArrowRight = styled.span`
  ${({ theme: { Consts } }) => css`
    height: 1px;
    display: block;
    width: 100%;
    background-color: white;
    position: relative;

    &::after {
      content: '>';
      font-size: 8px;
      color: white;
      position: absolute;
      right: -2px;
      top: -5px;
    }

    @media (max-width: ${Consts.tabletBreakPoint}) {
    }
  `}
`;

const ArrowDown = styled.span`
  ${({ theme: { Consts } }) => css`
    height: 100%;
    display: block;
    width: 1px;
    background-color: white;
    margin: 0 auto;
    position: relative;

    &::after {
      content: '>';
      font-size: 8px;
      color: white;
      position: absolute;
      right: -2px;
      bottom: -5px;
      transform: rotate(90deg);
    }

    @media (max-width: ${Consts.tabletBreakPoint}) {
    }
  `}
`;

const SideCountersWrapper = styled.span`
  ${({ theme: { Consts } }) => css`
    display: grid;
    grid-template-rows: 1fr 80px 1fr 80px 1fr 80px 1fr 80px;
    align-items: stretch;
    gap: 10px;
    padding-bottom: 50px;
    padding-top: 25px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      grid-template-columns: 1fr 1px 1fr;
      grid-template-rows: auto;
      padding-bottom: 0;
      padding-top: 0;
      max-width: 550px;
      width: 100%;
      margin: 0 auto;
    }
  `}
`;

const StatisticsContentWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: grid;
    grid-template-columns: 80px minmax(200px, 4fr) 160px minmax(100px, 3fr);
    align-items: stretch;
    gap: 30px;
    flex-grow: 1;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      grid-template-columns: 1fr;
    }

    > div {
      &:nth-child(2) {
        @media (min-width: ${Consts.tabletBreakPoint}) {
          display: grid;
          grid-template-rows: 1fr 1fr;
          align-items: stretch;
          padding-top: 20px;
          gap: 20px;
        }
      }
      &:nth-child(4) {
        @media (min-width: ${Consts.tabletBreakPoint}) {
          display: grid;
          grid-template-rows: 1fr 1fr;
          align-items: stretch;
          gap: 20px;
          padding-bottom: 30px;
          padding-top: 25px;
        }
      }
    }
  `}
`;

export default StatisticsSlide;
